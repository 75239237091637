.current-path-link {
  text-decoration: underline;
}

.current-path-link span {
  text-decoration: underline;
}

.page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.page-main {
  flex: 1;
}
